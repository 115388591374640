html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.quick-meet__paragraph--small {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.App {
  font-family: sans-serif;
  width: 100%;
  height: 100%;
}

.quick-meet {
  width: 100%;
  height: 100%;
  position: relative;
}

.quick-meet__card-wrap {
  position: absolute;
  z-index: 100;
  left: 10px;
  top: 10px;
  width: 400px;
  max-height: calc(100vh - 50px);
  overflow: auto;
  background: #fff;
  padding-top:50px;
}
.quick-meet__card-header {
  border-bottom: 2px solid rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  position: fixed;
  width: 400px;
  max-height: calc(100vh - 50px);
  z-index: 1;
  background: #fff;
  padding: 0 24px;
  height: 50px;
  top: 10px;
  left: 10px;
}
.quick-meet__card-header-tip {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.2)
}
.quick-meet__card-header--active .quick-meet__card-header-icon {
  transform: rotate(0deg);
}

.quick-meet__card-header-icon {
  transform: rotate(90deg);
}

.quick-meet__card-header-title {
  font-size: 18px;
  font-weight: bold;
}

.quick-meet__card-info--active {
  display: none;
}

.quick-meet__card {
  width: 100%;
}

.quick-meet__add-address {
  display: block !important;
  margin-top: 20px;
}

.quick-meet__search-btn {
  margin: 10px 16px;
}

.quick-meet__row {
  padding: 10px 16px;
}

.quick-meet__popover-content {
  width: 200px;
  display: inline-block;
}

.quick-meet__collapse-panel>.ant-collapse-header {
  display: flex;
  align-items: center;
}
.quick-meet__collapse-panel-title {
  font-size: 20px;
  font-weight: bold;
}

@media only screen and (max-width: 499px) {
  .quick-meet__card-wrap, .quick-meet__card-header {
    width: calc(100% - 20px);
  }
}